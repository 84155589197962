import { computed, inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { _isObject, _objectEntries } from '@naturalcycles/js-lib'
import { Assignment, Assignments, Experiment, getBucket, NO_AB } from '@naturalcycles/shared'
import { api } from '@src/app/core/services/api.service'
import { BucketEditService } from '@src/app/core/services/bucket-edit.service'
import { storageService } from '@src/app/core/services/storage.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class ExperimentService {
  private store = inject(SignalStore)
  private assignments$ = toObservable(computed(() => this.store.$experiment().assignments))
  private bucketEditService = inject(BucketEditService)

  filterAssignment(
    experiment: Experiment,
    assignment: Assignment | undefined,
  ): Assignment | undefined {
    const assignmentFromStorage = this.getAssignmentFromStorage(experiment)
    const forceAbKeys = storageService.get('forceAbKeys')

    if (assignmentFromStorage !== undefined && forceAbKeys === 'true') {
      return assignmentFromStorage
    }

    // Force an ABTest from the localstorage but after the conditions
    if (assignmentFromStorage !== undefined) {
      return assignmentFromStorage
    }

    return assignment
  }

  getAssignment(experiment: Experiment): Observable<Assignment | undefined> {
    return this.assignments$.pipe(
      map<Assignments, Assignment | undefined>(assignments => assignments[experiment]),
      distinctUntilChanged(),
      map(assignment => {
        if (assignment) {
          console.warn(`ExperimentService: Assignment to ${experiment} is ${getBucket(assignment)}`)
        }
        // else -> we don't have an assignment
        return assignment
      }),
      map(assignment => {
        return this.filterAssignment(experiment, assignment)
      }),
    )
  }

  async logImpression(experiment: Experiment): Promise<void> {
    await this.logEvent(experiment)
    return await this.bucketEditService.handleEdits(experiment)
  }

  /**
   * Logs an impression for an experiment if the current URL matches the assignment's impressionPage regex
   */
  async logImpressionFromUrl(url: string): Promise<void> {
    const assignments = this.store.$experiment().assignments
    _objectEntries(assignments).forEach(([experiment, assignment]) => {
      if (!_isObject(assignment)) {
        return
      }
      const impressionPage = assignment.data?.impressionPage
      if (!impressionPage) {
        return
      }
      const impressionPageRegex = new RegExp(impressionPage)
      if (impressionPageRegex.test(url)) {
        void this.logImpression(experiment as Experiment)
      }
    })
  }

  async logEvent(experiment: Experiment, event?: string, payload?: string): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      this.getAssignment(experiment).subscribe((assignment: Assignment | undefined) => {
        if (assignment === undefined) {
          return resolve()
        }

        console.warn(
          `ExperimentService: Impression logged for experiment ${experiment} in group ${getBucket(assignment)}`,
        )
        let url = `experiments/${experiment}`

        if (event) {
          url = `${url}/${event}`
        }

        if (assignment) {
          api
            .post(url, { json: { payload } })
            .then(() => resolve())
            .catch(reject)
        } else {
          resolve()
        }
      })
    })
  }

  getAssignmentFromStorage(experiment: Experiment): Assignment | undefined {
    // QA local storage values
    // removeAllAbtest = will return '' for all the ab test that are currently running
    const removeAllAbTest = storageService.get(NO_AB)
    if (removeAllAbTest === 'true') {
      return ''
    }

    // Check if the assignment is set inside localStorage for testing reasons
    // If the assignment exist, we return the bucket from there
    const assignmentForTesting = storageService.get(experiment)
    let parsed: Assignment | undefined
    // The assignment could be a string, so JSON.parse will throw an error
    try {
      if (assignmentForTesting) {
        parsed = JSON.parse(assignmentForTesting)
      }
    } catch {
      parsed = assignmentForTesting
    }
    return parsed
  }
}
