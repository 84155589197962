import { inject, Injectable } from '@angular/core'
import { BucketEditType, Experiment, getBucketEditsOfType } from '@naturalcycles/shared'
import { TranslateService } from '@ngx-translate/core'
import { firstValueFrom } from 'rxjs'
import { SignalStore } from '../store/signalStore'

@Injectable({ providedIn: 'root' })
export class BucketEditService {
  private translateService = inject(TranslateService)
  private store = inject(SignalStore)

  private async onTranslationBucketEdits(): Promise<void> {
    // The translateLoader takes care of applying the edits, so we just need to reload the language
    await firstValueFrom(this.translateService.reloadLang(this.translateService.currentLang))
  }

  public async handleEdits(experiment: Experiment): Promise<void> {
    const assignments = this.store.$experiment().assignments
    const assignment = assignments[experiment]
    if (!assignment) {
      return
    }
    const translationBucketEdits = getBucketEditsOfType(assignment, BucketEditType.TranslationKey)
    if (translationBucketEdits.length) {
      await this.onTranslationBucketEdits()
    }
  }
}
